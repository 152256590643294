import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Card from '../components/card'
import Img from "gatsby-image"
import Callout from "../components/callout"

const ServicesPage = ( {data} ) => {
	const {allServiceJson} = data
	return (
		<Layout>
			<SEO
				description="From fine art and shadow box framing to residential and commercial art consulting. L.A. Art Exchange is offers a wide array of custom services."
				title="Los Angeles & Santa Monica Framers"
			/>
			<main className="container lg:pt-12">
				<h1 className="text-center text-xl lg:text-2xl mb-6">
					Our Services
				</h1>

				<div className="mb-6 text-sm">
					<p>From our frame store in Santa Monica, L.A. Art Exchange is pleased to offer a wide array of services including custom framing, residential consultations, canvas stretching, and more. For over 30 years, we’ve had the privilege of framing your art, your keepsakes, your posters, and memorabilia. All materials we use are archival and acid free. Framing is our passion and your trust is our reward.</p>
				</div>

				<ul className="grid gap-10 md:grid-cols-2 md:gap-16 mb-12">
					{allServiceJson &&
					allServiceJson.nodes &&
					allServiceJson.nodes.map( ( {slug, ...props} ) => (
						<li key={slug}>
							<Card id={`services/${slug}/`} {...props} />
						</li>
					) )}
				</ul>
			</main>
			<aside>
				<div className=" container">
					<li className="bg-tan flex justify-center items-center py-10 px-4 lg:px-0">
						<div className="max-w-sm relative">
							<h2 className="text-base lg:text-lg mb-2">Delivery</h2>
							<p className="text-xs mb-6">
								Your art and your time are valuable. We have a dedicated driver
								on our team who can help facilitate your framing needs. Whether
								you need help bringing in your large painting, or if visiting
								our showroom doesn’t fit into your schedule, we can pick up your
								project, design in person or through email, and then deliver
								back to you when finished.
							</p>
							<Img
								className="lg:bottom-0 lg:-right-40 absolute mx-auto lg:-mt-24 lg:mr-10"
								fluid={data.deliveryTruck.childImageSharp.fluid}
								style={{width: `191px`, height: `90px`}}
							/>
						</div>
					</li>
				</div>
				<Callout/>
			</aside>
		</Layout>
	)
}

export const query = graphql`
  query {
    allServiceJson {
      nodes {
        title: name
        excerpt
        slug
        image: cover {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    deliveryTruck: file(relativePath: { eq: "delivery-truck.png" }) {
      childImageSharp {
        fluid(maxWidth: 382, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    frames: file(relativePath: { eq: "sample-remove-later/frames.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    instagram: file(relativePath: { eq: "sample-remove-later/instagram.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default ServicesPage
